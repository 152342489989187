import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import hamburger and times icons
import './categoryProductsPage.scss';
import { publicRequest } from '../../../requestMethods';
import ProductCard2 from '../ProductCard-2/ProductCard2';
import ProductCard4 from '../ProductCard/ProductCard4/ProductCard4';
// Starts here
const CategoryList = React.memo(({ categories, selectedCategories, onCategoryChange }) => (
  <ul className="category-list">
    {categories.map(cat => (
      <li key={cat.categoryID} className="category-item">
        <label className="category-label">
          <input
            type="checkbox"
            className="category-checkbox"
            checked={selectedCategories.has(cat.name)}
            onChange={() => onCategoryChange(cat.name)}
          />
          <span className="checkbox-custom"></span>
          <span className="category-name">{cat.name}</span>
        </label>
      </li>
    ))}
  </ul>
));
// Starts here
const CategoryProductsPage = () => {
  console.log("CategoryProductsPage");
  const location = useLocation();
  const navigate = useNavigate();
  const { sellerID } = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState('default');
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);


  const productContainerRef = useRef(null);

  useEffect(() => {
    fetchCategories();
  }, [sellerID]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(prev => !prev);
  }, []);

  //starts h
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get('category');
    if (categoryParam) {
      const categoryNames = categoryParam
        .split(',')
        .map(name => decodeURIComponent(name.trim()));
      setSelectedCategories(new Set(categoryNames));
    }
  }, [location.search]);

  const fetchCategories = async () => {
    try {
      const response = await publicRequest.get(`/category/getall/${sellerID}/public`, {
        withCredentials: true,
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const categoriesParam = Array.from(selectedCategories).join(',');
      const response = await publicRequest.get(`/product/category/${sellerID}/public`, {
        withCredentials: true,
        params: {
          categories: categoriesParam || undefined,
          sortOrder: sortOrder,
          page: page,
        }
      });
      
      setProducts(prevProducts => {
        const newProducts = response.data.products || [];
        return page === 1 ? newProducts : [...prevProducts, ...newProducts];
      });
      
      setHasMore(response.data.products.length === 20); // Assuming 20 products per page
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setIsLoading(false);
      setInitialLoadComplete(true);
    }
  }, [sellerID, selectedCategories, sortOrder, page]);

  useEffect(() => {
    if (selectedCategories.size > 0 || sortOrder !== 'default') {
      setPage(1);
      setProducts([]);
    }
    fetchProducts();
  }, [fetchProducts, selectedCategories, sortOrder]);

  useEffect(() => {
    if (page > 1) {
      fetchProducts();
    }
  }, [page, fetchProducts]);

  const handleCategoryChange = useCallback((categoryName) => {
    setSelectedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(categoryName)) {
        newSet.delete(categoryName);
      } else {
        newSet.add(categoryName);
      }
      return newSet;
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (selectedCategories.size > 0) {
      const encodedCategories = Array.from(selectedCategories)
        .map(category => encodeURIComponent(category))
        .join(',');
      searchParams.set('category', encodedCategories);
    }
    navigate(`/${sellerID}/categories?${searchParams.toString()}`, { replace: true });
  }, [selectedCategories, sellerID, navigate]);

  const handleSortChange = useCallback((event) => {
    setSortOrder(event.target.value);
  }, []);

  const loadMoreProducts = useCallback(() => {
    if (productContainerRef.current) {
      const currentScrollPosition = productContainerRef.current.scrollTop;
      const currentHeight = productContainerRef.current.scrollHeight;

      setPage(prevPage => prevPage + 1);

      // After the state update and re-render, restore the scroll position
      setTimeout(() => {
        if (productContainerRef.current) {
          const newHeight = productContainerRef.current.scrollHeight;
          productContainerRef.current.scrollTop = currentScrollPosition + (newHeight - currentHeight);
        }
      }, 100);
    }
  }, []);

  const memoizedProductCards = useMemo(() => (
    products.map(product => (
      // <ProductCard2 key={product.id} product={product} />
      <ProductCard4 key={product.id} product={product} />
    ))
  ), [products]);

  const handleCategoriesClick = useCallback(() => {
    setSelectedCategories(new Set());
    navigate(`/${sellerID}/categories`);
  }, [sellerID, navigate]);

  const breadcrumb = useMemo(() => {
    const parts = [
      <Link key="home" to={`/${sellerID}`}>Home</Link>,
      <Link key="categories" to={`/${sellerID}/categories`} onClick={(e) => {
        e.preventDefault();
        handleCategoriesClick();
      }}>Categories</Link>
    ];

    if (selectedCategories.size === 1) {
      const category = Array.from(selectedCategories)[0];
      parts.push(<span key="category">{category}</span>);
    } else if (selectedCategories.size > 1) {
      parts.push(<span key="multiple">Multiple Categories</span>);
    }

    return parts.reduce((prev, curr, index) => [
      prev,
      <span key={`separator-${index}`}> &gt; </span>,
      curr
    ]);
  }, [sellerID, selectedCategories, handleCategoriesClick]);


 const noProductsMessage = useMemo(() => {
    if (initialLoadComplete && products.length === 0 && !isLoading) {
      return `No products found ${selectedCategories.size > 0 ? 'in selected categories' : ''}`;
    }
    return null;
  }, [initialLoadComplete, selectedCategories, products, isLoading]);


  return (
    <div className="category-products-page">
      <nav className="category-breadcrumb">
        {breadcrumb}
      </nav>
      
      <header className="category-page-header">
        {isSmallScreen && (
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            <FaBars />
          </button>
        )}
        <h1>{selectedCategories.size === 0 ? 'All Categories' :
          selectedCategories.size === 1 ? Array.from(selectedCategories)[0] :
            'Multiple Categories'}</h1>
        <select onChange={handleSortChange} value={sortOrder}>
          <option value="default">Default</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
        </select>
      </header>

      <div className="category-content-wrapper">
        <aside className={`category-sidebar ${isSidebarOpen ? 'open' : ''}`}>
          {isSmallScreen && (
            <button className="sidebar-close" onClick={toggleSidebar}>
              <FaTimes />
            </button>
          )}
          <h3 className="sidebar-title">Categories</h3>
          <CategoryList
            categories={categories}
            selectedCategories={selectedCategories}
            onCategoryChange={handleCategoryChange}
          />
        </aside>

        <div className="category-products-container" ref={productContainerRef}>
          <div className="category-products-grid">
            {memoizedProductCards}
            {noProductsMessage && (
              <div className="no-products-message">{noProductsMessage}</div>
            )}
            {isLoading && <div className="loading-spinner">Loading...</div>}
          </div>
          
          {hasMore && (
            <div className="load-more-container">
              <button 
                onClick={loadMoreProducts} 
                className="category-load-more"
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Load More'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryProductsPage;

// config/planConfig.js

export const planFeatures = {
    'Free Plan': [
      '1 Product',
      '1 Warehouse',
      'Custom shipping options'
    ],
    'Premium': [
      '100 Products',
      'Up to 1000 Orders',
      'Unlimited Warehouses',
      'Shipping Provider integrations (Delhivery, Shiprocket)',
      'Bulk Order processing',
      'Order tracking for customers',
      'Product Categories',
      'Analytics Dashboard'
    ],
    'Premium Plus': [
      'Everything in Premium',
      'Unlimited Products',
      'No Order Limit',
      'Payment Gateway Integration'
      // Add any additional features here if needed
    ]
  };
  
  export const planLimits = {
    'Free Plan': {
      productLimit: 1,
      orderLimit: 100
    },
    'Premium': {
      productLimit: 100,
      orderLimit: 10000
    },
    'Premium Plus': {
      productLimit: Infinity, // Use Infinity for unlimited
      orderLimit: Infinity
    }
  };
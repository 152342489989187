// seller/src/components/FeatureAccessDenied/FeatureAccessDenied.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './featureAccessDenied.scss'; // Optional: Add styles for the component

const FeatureAccessDenied = ({ message}) => {
    const navigate = useNavigate();

    return (
        <div className="feature-access-denied">
            <h2>Access Denied</h2>
            <p>{message}</p>
            <button onClick={() => navigate('/plans')}>Upgrade Plan.</button>
        </div>
    );
};

export default FeatureAccessDenied;
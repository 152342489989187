import { useDispatch, useSelector } from 'react-redux';
import './navbar.scss'
import { updateSubscriptionData } from '../../redux/sellerRedux';

const Navbar = () => {

    const sellerData = useSelector((state) => state.seller.sellerData);
    const dispatch = useDispatch();
    // console.log("sellerData", sellerData);
    const reduxNull = () => {
        dispatch(updateSubscriptionData(null));
    }

    return (
        < div className='navbar-seller'>


            < div className="navbar-seller__user">
                {/* <img src="https://www.tikktap.com/Graphics/Handwave-2.svg" alt="Hand Wave Image" /> */}
                {/* <span><em>Hi</em> Admin<em>!</em></span> */}
                <h3>Prodinent | {sellerData.sellerName}</h3>
                {/* <button onClick={reduxNull}>Clear Subscription Data</button> */}

            </div>

        </div >
    )
}

export default Navbar
import React, { useEffect, useState } from 'react';
import './SubscriptionPlans.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sellerRequest } from '../../requestMethods';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { planFeatures, planLimits } from './planConfig';
import sellerRedux, { updateSubscriptionData } from '../../redux/sellerRedux';

// Assuming you have these mappings


// const plans = [
//   {
//     name: 'Free Plan',
//     price: '₹0',
//     period: '/ forever',
//     features: [
//       '1 Product',
//       '1 Warehouse',
//       'Custom shipping options',
//     ],
//     buttonText: 'Get Started',
//     isPopular: false,
//   },
//   {
//     name: 'Starter',
//     price: '₹299',
//     period: '/ month',
//     features: [
//       'Everything in Free Plan',
//       '5 Products',
//       '2 Warehouses',
//     ],
//     buttonText: 'Choose Plan',
//     isPopular: false,
//   },
//   {
//     name: 'Premium',
//     price: '₹499',
//     period: '/ month',
//     features: [
//       'Everything in Starter Plan',
//       '100 Products',
//       'Unlimited Warehouses',
//       'Shipping Provider integrations (Delhivery, Shiprocket)',
//       'Bulk Order processing',
//       'Announcement Bar',
//       'Direct Checkout feature',
//       'WhatsApp customer support',
//       'Order tracking for customers',
//     ],
//     buttonText: 'Choose Plan',
//     isPopular: true, // Highlighted as Most Popular
//   },
//   {
//     name: 'Infinity',
//     price: '₹999',
//     period: '/ month',
//     features: [
//       'Everything in Premium',
//       'Unlimited Products',
//       'Custom Domain',
//       'Payment Gateway integration',
//       'WhatsApp Business API integration',
//     ],
//     buttonText: 'Choose Plan',
//     isPopular: false,
//   },
// ];


const plans = [
  {
    name: 'Free Plan',
    price: '₹0',
    period: '/ forever',
    features: [
      '1 Product',
      '1 Warehouse',
      'Custom shipping options',
    ],
    buttonText: 'Get Started',
    isPopular: false,
  },
  {
    name: 'Premium',
    price: '₹10',
    period: '/ week',
    features: [

      '100 Products',
      'Up to 1000 Orders/month',
      'Unlimited Warehouses',
      'Shipping Provider integrations (Delhivery, Shiprocket)',
      'Bulk Order processing',
      'Order tracking for customers',
      'Product Categories',
      'Analytics Dashboard',

    ],
    buttonText: 'Choose Plan',
    isPopular: true, // Highlighted as Most Popular
  },
  {
    name: 'Premium Plus',
    price: '₹999',
    period: '/ month',
    features: [
      'Everything in Premium',
      'Unlimited Products',
      'No Order Limit',
      'Payment Gateway Integration',
      // 'Custom Domain',
      // 'Meta Tags',
      // 'WhatsApp Business API integration',
      // 'Testimonials',
      // 'Product Reviews',
    ],
    buttonText: 'Choose Plan',
    isPopular: false,
  },
];

const SubscriptionPlans = () => {
  // const currentPlan = 'Free Plan'; 
  const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
  const [currentPlan, setCurrentPlan] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const subscriptionData = useSelector((state) => state.seller?.subscriptionData);
  console.log("subscriptionData from plans", subscriptionData);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = async (planName, amount) => {
    console.log("planName", planName);
    console.log("amount", amount);
    try {

      const getSubscriptionData = await sellerRequest.post(`/subscription/create-razorpay-subscription/`, {
        plan: planName
      });
      console.log("subscriptionResponse", getSubscriptionData.data.subscriptionResponse);
      const subscriptionID = getSubscriptionData.data.subscriptionResponse.id;
      console.log("Subscription ID", subscriptionID);
      if (subscriptionID) {
        await openRazorpayCheckout(subscriptionID, 10, planName);
      }
    } catch (error) {

    }

  }
  const updateSubscription = async (planName, subscriptionID, currentStart, currentEnd, shortUrl, authAttempts) => {
    console.log("planName", planName);
    console.log("subscriptionID", subscriptionID);
    console.log("subscriptionData", subscriptionData);
    console.log("currentStart", currentStart);
    console.log("currentEnd", currentEnd);
    console.log("shortUrl", shortUrl);
    console.log("authAttempts", authAttempts);
    setLoading(true);

    try {
      const features = planFeatures[planName] || [];
      const { productLimit, orderLimit } = planLimits[planName] || { productLimit: 0, orderLimit: 0 };

      if (!subscriptionData) {
        console.log("subscriptionData does not exist");
        const subscriptionNewResponse = await sellerRequest.post(`/subscription/create-subscription/${sellerID}`, {
          sellerID,
          subscriptionID,
          plan: planName,
          startDate: new Date(),
          endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)), // 1 month later
          currentStart: currentStart,
          currentEnd: currentEnd,
          shortUrl: shortUrl,
          authAttempts: authAttempts,
          billingCycle: 'Monthly', // assuming monthly for simplicity
          amount: parseFloat(plans.find(plan => plan.name === planName).price.replace('₹', '')), // get the price
          features,
          currency: 'INR',
          productLimit,
          orderLimit
        });

        console.log("Created new subscription", subscriptionNewResponse.data);
        // Uncomment the following line if you want to update the global state
        dispatch(updateSubscriptionData(subscriptionNewResponse.data));

      } else {
        console.log("subscriptionData already exists");
        const subscriptionUpdateResponse = await sellerRequest.put(`/subscription/update-subscription/${sellerID}`, {
          plan: planName,
          features,
          productLimit,
          orderLimit
        });
        console.log("Updated subscription", subscriptionUpdateResponse.data);
        dispatch(updateSubscriptionData(subscriptionUpdateResponse.data));
      }

      setCurrentPlan(planName); // Update the state with the newly selected plan
      setSnackbarMessage('Plan updated successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error updating the subscription plan', error);
      setSnackbarMessage('Failed to update the subscription plan.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true); // Show the snackbar after the operation
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  useEffect(() => {
    console.log("subscriptionData from plans useEffect", subscriptionData);
    if (subscriptionData?.plan) {
      setCurrentPlan(subscriptionData.plan);
    }
  }, [subscriptionData]);



  const openRazorpayCheckout = async (subscriptionID, amount, planName) => {
    const options = {
      key: 'rzp_test_lSizqoqj3UmZTc', //test
      // key: 'rzp_live_SETRD4E1GT4g6B', //live
      subscription_id: subscriptionID,
      amount: amount, // Amount in the smallest currency unit
      currency: 'INR',
      name: 'Prodinent Digital',
      description: 'Subscription Payment',
      handler: async function (response) {
        // Handle successful payment here
        console.log(response);
        const paymentId = response.razorpay_payment_id;
        const orderId = response.razorpay_order_id;
        const signature = response.razorpay_signature;

        try {
          const verifyPaymentResponse = await sellerRequest.post(`/subscription/verifyPayment/${sellerID}`, {
            paymentId,
            orderId,
            signature,
            sellerID: sellerID,
            mode: "test",  //test or live
            // mode: "test",  //test or live


          });

          console.log("verifyPaymentResponse", verifyPaymentResponse);
          if (verifyPaymentResponse.data.status.status === "captured") {
            console.log("Payment verified successfully");
            console.log("Checking subscription with ID", subscriptionID);

            try {
              // Once the payment is completed, fetch subscription data again
              const subscriptionData_razorpay = await sellerRequest.post(`/subscription/fetch-subscription/${sellerID}/${subscriptionID}`);
              console.log("RES: subscriptionData_razorpay", subscriptionData_razorpay.data);
              console.log("RES: auth_attempts", subscriptionData_razorpay.data.auth_attempts);

              const { current_start, current_end, short_url, auth_attempts } = subscriptionData_razorpay.data;

               const updatedSubscriptionData = await updateSubscription(planName, subscriptionID, current_start, current_end, short_url, auth_attempts);

              console.log("updatedSubscriptionData", updatedSubscriptionData);
            } catch (error) {
              console.error('Error fetching or updating subscription data', error);
            }
          }

        } catch (error) {

        }


      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '9999999999'
      },
      theme: {
        color: '#F37254'
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  }

  return (
    <div className="subscription-plans">
      <h1 className="subscription-plans__title">Choose Your Plan</h1>
      <div className="subscription-plans__container">
        {plans.map((plan, index) => (
          <div
            className={`plan-card ${plan.isPopular ? 'plan-card--popular' : ''} ${plan.name === currentPlan ? 'plan-card--current' : ''}`}
            key={index}
          >
            {plan.isPopular && <div className="plan-card__badge">Most Popular</div>}
            <h2 className="plan-card__name">{plan.name}</h2>
            <div className="plan-card__price">
              <span className="plan-card__price-value">{plan.price}</span>
              <span className="plan-card__price-period">{plan.period}</span>
            </div>
            <ul className="plan-card__features">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="plan-card__feature">
                  {feature}
                </li>
              ))}
            </ul>
            <button className="plan-card__button"
              disabled={plan.name === currentPlan}
              onClick={() => handleSubmit(plan.name, plan.price)}
            >
              {plan.name === currentPlan ? 'Current Plan' : plan.buttonText}
            </button>
          </div>
        ))}
      </div>
      {loading && (
        <Backdrop
          open
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  );
};

export default SubscriptionPlans;

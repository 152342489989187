import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { motion } from 'framer-motion';
import './TrackOrder.scss';

const steps = [
  'Order Placed',
  'Order Confirmed',
  'Order Processing',
  'Manifested',
  'Shipped',
  'In Transit',
  'Delivered'
];

const TrackOrderPage = ({ orderData }) => {
  if (!orderData) {
    return <div className="error">Order not found.</div>;
  }
  const backgroundColor = orderData.status === 'Cancelled' ? 'red' : '#4caf50';

  const getStepIndex = (status) => {
    return steps.indexOf(status);
  };

  return (
    <motion.div
      className="track-order"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="order-card">
        <h1>Track Your Order</h1>
        <p className="order-id">Seller ID: {orderData.sellerID}</p>

        <div className="stepper-container">
          <Stepper activeStep={getStepIndex(orderData.status)} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="order-details">
          <motion.div
            className="status-badge"
            initial={{ scale: 0.8 }}
            style={{ backgroundColor }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            {orderData.status}
          </motion.div>
          <p className="shipping-service">
            Shipping via <span>{orderData.shippingService || 'Not Yet Confirmed'}</span>
          </p>
        </div>
        <footer className="powered-by">
        <p>Powered by <a href="https://prodinent.com" target="_blank" rel="noopener noreferrer">Prodinent</a></p>
        <p>Empowering businesses with seamless e-commerce solutions</p>
      </footer>
      </div>
    </motion.div>
  );
};

export default TrackOrderPage;
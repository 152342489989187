import React, { useState, useRef, useEffect } from 'react'
import './navbar.scss'
import { Link } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import { changeCartVisibility } from '../../../redux/settingsRedux';
import Cart from '../Cart/Cart';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import { uploadImage } from '../../../utils/Firebase';
import SearchIcon from '@mui/icons-material/Search';
const Navbar = ({ navbarData, sellerID }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const products = useSelector(state => state.store.products); // Assuming products are in Redux store
    // console.log(products);
    const searchBoxRef = useRef(null);
    const quantity = useSelector(state => state.cart.products.length)
    const showCart = useSelector(state => state.settings.showCart)
    const link = sellerID;
    const cartProducts = useSelector(state => state.cart.products);
    const sellerCartItems = cartProducts.filter(product => product.sellerID === sellerID);

    // Get the number of unique items (not based on quantity)
    const sellerUniqueItemCount = sellerCartItems.length;

    // console.log("Navbar data", navbarData);
    // console.log("seller ID", sellerID);
    // const logoSrc = store?.logo ? store.logo : 'https://firebasestorage.googleapis.com/v0/b/cartify-0246.appspot.com/o/Test%2FScreenshot%202024-05-18%20001339.png?alt=media&token=73acfe5f-c566-4e8c-8eda-2500552484af';
    const dispatch = useDispatch()



    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length > 0) {
            const filtered = products.filter(product =>
                product.title.toLowerCase().includes(query.toLowerCase())
            );
            // console.log("Filtered products", filtered);

            setFilteredProducts(filtered);
        } else {
            setFilteredProducts([]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            setFilteredProducts([]);
        }
    };
    const handleClickOutside = (event) => {
        // console.log("Click detected on:", event.target);
        if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
            // console.log("Outside click detected");
            setFilteredProducts([]);
        }
    };
    useEffect(() => {
        // Attach event listeners to the document
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('click', handleClickOutside); // Use 'click' instead of 'mousedown'

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    if (!navbarData) {
        return null;

    }


    return (
        <div className='navbar' style={{ backgroundColor: navbarData.backgroundColor }}>
            <div className="left">
                <Link to={`/${link}`}>
                    {navbarData?.logo && <img src={navbarData.logo} alt="Store Logo" />}
                </Link>
            </div>
            <div className="center">
                {navbarData?.isSearchBoxActive && (
                    <div className="search-box" ref={searchBoxRef} style={{ backgroundColor: navbarData.backgroundColor }}>
                        <input
                            type="text"
                            placeholder="Search products..."
                            className="search-input"
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                        {/* <SearchIcon className="search-icon" /> */}
                        {filteredProducts.length > 0 && (
                            <ul className="search-results">
                                {filteredProducts.map(product => (
                                    <li key={product.id}>
                                        <Link
                                            to={`/${sellerID}/${product.productID}`}
                                            className="search-result-link"
                                            onClick={() => setFilteredProducts([])} // Hide list on click
                                        >
                                            {`${product.title}`}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
            <div className="right">
                <Badge badgeContent={sellerUniqueItemCount} color="secondary" onClick={() => {
                    dispatch(changeCartVisibility(!showCart));
                }}>
                    <ShoppingBagIcon />
                </Badge>
                <Cart />
            </div>
        </div>
    )
}

export default Navbar
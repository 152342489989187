import React from 'react';
import './ExpiredPlan.scss';
import { sellerRequest } from '../../../requestMethods';
import { updateSubscriptionData } from '../../../redux/sellerRedux';
import { useDispatch, useSelector } from 'react-redux';
import { openRazorpayCheckout } from '../../../pages/SubscriptionPlans/RazorpayUtil';

const ExpiredPlan = () => {
    const dispatch = useDispatch();
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    console.log("sellerID from plan expired", sellerID);
    const currentSubscriptionData = useSelector((state) => state.seller?.subscriptionData);
    console.log("currentSubscriptionData", currentSubscriptionData);
    
    const renewSubscription = async (sellerID) => {
        try {
            const now = new Date();
            // const endDate = new Date(now.getTime() + 2 * 60 * 1000); // Set endDate to 2 minutes from now
            const endDate = new Date(new Date().setDate(new Date().getDate() + 1))

            const response = await sellerRequest.put(`/subscription/${sellerID}`, {
                status: 'Active',
                startDate: new Date(),
                endDate: endDate,
            });

            console.log("Subscription renewed successfully", response.data);
            return response.data; // Return the updated subscription data
        } catch (error) {
            console.error("Failed to renew subscription", error);
            throw error; // Re-throw the error for further handling
        }
    };
    const handleRenew = async () => {
        const subscriptionID = currentSubscriptionData.subscriptionID;
        const planName = currentSubscriptionData.plan;  
        const amount = currentSubscriptionData.amount;
        try {
            await openRazorpayCheckout(subscriptionID, amount, planName, sellerID);

            // const updatedSubscription = await renewSubscription(sellerID);
            // dispatch(updateSubscriptionData(updatedSubscription)); // Update Redux store with new data
            // onRenew(); // Navigate to the appropriate page or update UI
        } catch (error) {
            console.error("Error renewing subscription", error);
            // Optionally handle error, e.g., show a notification
        }
    };
    return (
        <div className="expired-plan">
            <div className="expired-plan__content">
                <h2 className="expired-plan__title">Your Plan Has Expired</h2>
                <p className="expired-plan__message">Click the button below to renew your subscription.</p>
                <button className="expired-plan__button" onClick={handleRenew}>
                    Renew Now
                </button>
            </div>
        </div>
    );
};

export default ExpiredPlan;
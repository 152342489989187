import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import './productPage.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { addProduct } from '../../../redux/cartRedux';
import { changeCartVisibility } from '../../../redux/settingsRedux';
import Cart from '../Cart/Cart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useLocation } from 'react-router-dom';
import { publicRequest } from '../../../requestMethods';
import { Skeleton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { storeData } from '../../../redux/storeRedux'
import { storeAllProducts } from '../../../redux/storeRedux';
import WA_Icon from './Assets/WhatsApp_icon.png'
import DOMPurify from 'dompurify';
const ProductPage = () => {
    const params = useParams();
    const location = useLocation();
    const sellerID = location.pathname.split("/")[1];
    const productID = location.pathname.split("/")[2];
    const seller_id = location.pathname.split("/")[1]
    const [selectedImg, setSelectedImg] = useState(0);
    const [imageLoading, setImageLoading] = useState(false);
    const [qty, setQty] = useState(1);
    const dispatch = useDispatch();
    const showCart = useSelector(state => state.settings.showCart);
    const proId = params.id;
    const navigate = useNavigate();
    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(true);
    // const [store, setStore] = useState()
    const [storeData, setStoreData] = useState()
    const [whatsApp, setWhatsApp] = useState()
    const [packageDimensionsID, setPackageDimensionsID] = useState()
    const [editorContent, setEditorContent] = useState('');

    const [isExpanded, setIsExpanded] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            const res = await publicRequest.get(`/product/${sellerID}/${productID}/public`, {
                withCredentials: true,
            });
            setProduct(res.data);
            // console.log("Product", res.data);
            setPackageDimensionsID(res.data.packageDimensionsID)
            const storeResponse = await publicRequest.get(`/seller/find/${seller_id}/public`, {
                withCredentials: true,
            });
            // console.log("Store data", storeResponse.data);

            setStoreData(storeResponse.data.store[0])
            setWhatsApp(storeResponse.data.store[0].WA_support)

            // if (storeResponse) dispatch(storeData(storeResponse.data));
            setLoading(false); // Set loading to false when the product is fetched
        } catch (error) {
            console.error("Error fetching product:", error);
            setLoading(false); // Set loading to false even if there's an error
        }
    }, [sellerID, productID]);

    useEffect(() => {
        fetchData();
    }, [productID]);

    useEffect(() => {
        setSelectedImg(0);
    }, [product]);

    const sanitizeHTML = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    };

    const isHTML = (str) => {
        const doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    };
    const truncateDescription = (desc) => {
        if (isHTML(desc)) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = DOMPurify.sanitize(desc);
            const text = tempDiv.textContent || tempDiv.innerText;
            const words = text.split(' ');
            if (words.length > 25) {
                return words.slice(0, 25).join(' ') + '...';
            }
            return desc;
        } else {
            const words = desc.split(' ');
            if (words.length > 25) {
                return words.slice(0, 25).join(' ') + '...';
            }
            return desc;
        }
    };

    const renderDescription = (desc, isTruncated) => {
        if (isHTML(desc)) {
            return (
                <div
                    className="product-description"
                // dangerouslySetInnerHTML={sanitizeHTML(isTruncated ? truncateDescription(desc) : desc)}

                />
            );
        } else {
            return <p>{isTruncated ? truncateDescription(desc) : desc}</p>;
        }
    };


    useEffect(() => {
        // console.log("Store:", storeData);
        if (storeData) {

            document.title = storeData?.storeName ? storeData.storeName : 'Prodinent';


            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
                if (storeData.navigationBar.logo) {

                    favicon.href = storeData?.navigationBar.logo;
                }
            }
        }
    }, [storeData]);

    const [fullScreenImage, setFullScreenImage] = useState(null);
    const [fullscreenImageIndex, setFullscreenImageIndex] = useState(0);
    const [showFullDescription, setShowFullDescription] = useState(true);
    const openFullScreenImage = () => {
        setFullScreenImage(product.image[selectedImg]?.src);
    };

    const closeFullScreenImage = () => {
        setFullScreenImage(null);
    };
    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };
    const getShortDescription = (html) => {
        const text = html
        const words = text.split(' ').slice(0, 25).join(' '); // Get the first 25 words
        return words + (text.split(' ').length > 25 ? ' See more...' : ''); // Append ellipsis if needed
    };
    // const truncateDescription = (desc) => {
    //     const words = desc.split(' ');
    //     if (words.length > 25) { // Adjust the number of words to truncate as needed
    //         return words.slice(0, 25).join(' ') + '...';
    //     }
    //     return desc;
    // };


    const generateWhatsAppMessage = () => {
        const sellerPhoneNumber = whatsApp.mobileNumber;
        const message = `Hello!\nI'm interested in the product *${product.title}* with ID *${product.productID}*.\nCould you please provide more details?`;
        return `https://wa.me/${sellerPhoneNumber}?text=${encodeURIComponent(message)}`;
    };

    const handleWhatsAppClick = () => {
        const url = generateWhatsAppMessage();
        window.open(url, '_blank');
    };
    const getYouTubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const shortRegExp = /^.*(youtu.be\/|youtube.com\/)(shorts\/|v\/)([^#\&\?]*).*/;
        const match = url.match(regExp);
        const shortMatch = url.match(shortRegExp);

        if (match && match[2].length === 11) {
            return match[2];
        } else if (shortMatch && shortMatch[3].length === 11) {
            return shortMatch[3];
        }
        return null;
    };

    const memoizedProduct = useMemo(() => product, [product]);

    // if (loading) {
    //     return (
    //         <div className="loaderWrapper">
    //             <Bars
    //                 height="50"
    //                 width="50"
    //                 color="#0b2b66"
    //                 ariaLabel="loading"
    //                 className="loaderbox"
    //             />
    //         </div>
    //     );
    // }

    return (
        <>
            <div className="product-page">
                {loading ? (
                    <div className="productPage">
                        <div className="productPage__left">
                            <div className="productPage__left-images">
                                <Skeleton variant="rectangular" width="53%" height="65px" />
                                <Skeleton variant="rectangular" width="53%" height="65px" />
                                <Skeleton variant="rectangular" width="53%" height="65px" />
                            </div>
                            <div className="productPage__left-mainImage">
                                <Skeleton variant="rectangular" width="300px" height="300px" />
                            </div>
                        </div>
                        <div className="productPage__right">
                            <Skeleton variant="text" width="80%" height="40px" />
                            <Skeleton variant="text" width="60%" height="20px" />
                            <Skeleton variant="rectangular" width="40%" height="40px" />
                            <Skeleton variant="rectangular" width="60%" height="40px" />
                            <Skeleton variant="rectangular" width="100%" height="50px" />
                            <Skeleton variant="rectangular" width="100%" height="50px" />
                        </div>
                    </div>
                ) : (

                    <>

                        <div className="productPage">
                            <div className="productPage__left">
                                <div className="productPage__left-images">
                                    {memoizedProduct?.image.map((photo, index) => (
                                        <img
                                            key={index}
                                            src={photo.src}
                                            alt="Product Images"
                                            className={selectedImg === index ? 'selected' : ''}
                                            onClick={() => {
                                                if (selectedImg !== index) {
                                                    setSelectedImg(index);
                                                    setImageLoading(true);
                                                    setFullscreenImageIndex(index);
                                                }
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className="productPage__left-mainImage">
                                    {imageLoading && (
                                         <Skeleton variant="rectangular" width="300px" height="300px" />
                                    )}
                                    <img
                                        style={{ display: imageLoading ? 'none' : 'block' }}
                                        src={memoizedProduct.image[selectedImg]?.src}
                                        alt="Product Image"
                                        onLoad={() => setImageLoading(false)}
                                        onClick={openFullScreenImage}
                                    />
                                </div>
                            </div>
                            <div className="productPage__right">
                                <div className="productPage__right-title">
                                    <h1>{memoizedProduct.title}</h1>
                                </div>
                                {/* <div onClick={toggleDescription}>
                                    {renderDescription(memoizedProduct.desc, !showFullDescription)}
                                </div> */}
                                <div className="product-description">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: isExpanded ? memoizedProduct.desc : DOMPurify.sanitize(getShortDescription(memoizedProduct.desc)),
                                        }}
                                        onClick={toggleDescription}
                                    />
                                    {/* {!isExpanded && (
                                        <button onClick={toggleDescription}>See More</button>
                                    )}
                                    {isExpanded && (
                                        <button onClick={toggleDescription}>See Less</button>
                                    )} */}
                                </div>
                                <div className="productPage__right-price">
                                    <h1>₹{memoizedProduct.price}/-</h1>
                                    <div className="productPage__right-price-MRP">
                                        <div>
                                            <h6>MRP :</h6>
                                        </div>
                                        <div>
                                            <h6 className="strikePrice">₹{memoizedProduct.MRP}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="productPage__right-qty">
                                    <div>
                                        <span className="qty-label">Quantity:</span>
                                        <button className="qty-button" onClick={() => setQty(qty > 1 ? qty - 1 : 1)}>
                                            -
                                        </button>
                                        <span className="qty">{qty}</span>
                                        <button className="qty-button" onClick={() => setQty(qty + 1)}>
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className="productPage__right-button">
                                    <div>
                                        <button
                                            className="primaryButton-outline"
                                            onClick={() => {
                                                dispatch(
                                                    addProduct({
                                                        _id: memoizedProduct._id,
                                                        productID: memoizedProduct.productID,
                                                        warehouseID: memoizedProduct.warehouseID,
                                                        productName: memoizedProduct.title,
                                                        unitPrice: memoizedProduct.price,
                                                        qty: qty,
                                                        totalPrice: memoizedProduct.price * qty,
                                                        image: memoizedProduct.image[0]?.src,
                                                        productWeight: memoizedProduct.productWeight,
                                                        sellerID: memoizedProduct.sellerID,
                                                        packageDimensionsID: packageDimensionsID,
                                                    })
                                                );
                                                dispatch(changeCartVisibility(!showCart));
                                            }}
                                        >
                                            Add to Cart
                                        </button>

                                    </div>
                                    <div>
                                        <button
                                            className="primaryButton"
                                            onClick={() => {
                                                dispatch(
                                                    addProduct({
                                                        _id: memoizedProduct._id,
                                                        productID: memoizedProduct.productID,
                                                        productName: memoizedProduct.title,
                                                        unitPrice: memoizedProduct.price,
                                                        qty: qty,
                                                        totalPrice: memoizedProduct.price * qty,
                                                        image: memoizedProduct.image[0]?.src,
                                                        productWeight: memoizedProduct.productWeight,
                                                        sellerID: memoizedProduct.sellerID,
                                                        packageDimensionsID: packageDimensionsID,
                                                    })
                                                );
                                                navigate(`/${sellerID}/checkout/`);
                                            }}
                                        >
                                            Buy it now
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* Product Video */}
                        {memoizedProduct.video && memoizedProduct.video.isActive && memoizedProduct.video.url && (
                            <div className="product_video">
                                <h2>{memoizedProduct.video.title || "Product Video"}</h2>
                                {/* {memoizedProduct.video.thumbnail && (
                                    <img
                                        src={memoizedProduct.video.thumbnail}
                                        alt="Video thumbnail"
                                        className="productPage__video-thumbnail"
                                    />
                                )} */}
                                <iframe
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(memoizedProduct.video.url)}`}
                                    title={memoizedProduct.video.title || "Product Video"}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="basket-icon" onClick={() => dispatch(changeCartVisibility(!showCart))}>
                <ShoppingCartIcon />
            </div>
            {whatsApp?.isActive &&
                <div className="whatsapp-icon" onClick={handleWhatsAppClick}>
                    {/* <WhatsAppIcon /> */}
                    <img src={WA_Icon} alt="WhatsApp Icon" />
                </div>
            }
            {fullScreenImage !== null && (
                <div className="fullscreen-image-overlay" onClick={closeFullScreenImage}>
                    <Swiper
                        spaceBetween={10}
                        pagination={{ clickable: true }}
                        modules={[Pagination]}
                        navigation={{ prevEl: '.fullscreen-image-overlay-prev', nextEl: '.fullscreen-image-overlay-next' }}
                        initialSlide={fullscreenImageIndex}
                    >
                        {memoizedProduct.image.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img src={image.src} alt={`Full-screen product image ${index}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            <Cart />
        </>
    );
};

export default ProductPage;

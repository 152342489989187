import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './OrderDismissedOverlay.scss';

const OrderDismissedOverlay = ({ orderID, setShowOverlay, handleClose, onRetryPayment, setLoading }) => {
  const [isRetrying, setIsRetrying] = useState(false);

  const handleRetryClick = () => {
    setIsRetrying(true);
    setLoading(true);
    onRetryPayment().finally(() => {
      setIsRetrying(false);
      setLoading(false);
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="order-dismissed-overlay"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="overlay-content"
      >
        <button onClick={handleClose} className="close-button" aria-label="Close">
          <svg viewBox="0 0 24 24" width="24" height="24">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </button>
        <div className="content-wrapper">
          <div className="error-icon">
            <span onClick={handleClose}>×</span>
          </div>
          <h2>Payment Failed</h2>
          <p className="error-message">
            We're sorry, but your payment couldn't be processed. Please try again.
          </p>
          <div className="order-details">
            <h3>Order Details</h3>
            <p>Order ID: {orderID}</p>
            {/* <p>Razorpay Order ID: {razorpayOrderID}</p> */}
          </div>
          <button
            onClick={handleRetryClick}
            className={`retry-button ${isRetrying ? 'retrying' : ''}`}
            disabled={isRetrying} // Disable the button when retrying
          >
            Retry Payment
          </button>
          {isRetrying && <p className="retry-message">Please wait, do not refresh or close the window.</p>}

        </div>
      </motion.div>
    </motion.div>
  );
};

export default OrderDismissedOverlay;

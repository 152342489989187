import { useState } from "react";
import { sellerRequest } from "../../requestMethods";


export const deleteOrder = async (orderData, sellerID, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, handleBack) => {
  // const isConfirmed = window.confirm('Are you sure you want to delete this order?');
  // if (!isConfirmed) return;

  try {
    const res = await sellerRequest.delete(`/order/delete/${sellerID}/${orderData._id}`, {
      withCredentials: true
    });
    setLoading(false);
    setSnackbarMessage('Order deleted successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    handleBack();
  } catch (error) {
    setLoading(false);
    setSnackbarMessage('Failed to delete order!');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

export const cancelOrder = async (orderData, sellerID, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen) => {
  // const isConfirmed = window.confirm('Are you sure you want to cancel this order?');
  // if (!isConfirmed) return;

 
  const updatedOrderData = { ...orderData, status: "Cancelled", profit: 0 };
  try {
    const response = await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
 
    const remark = "Order Cancelled";
    await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: remark });

    setOrderData(prevOrderData => ({
      ...prevOrderData,
      status: 'Cancelled',
      profit: 0,
      orderTimeline: [
        ...prevOrderData.orderTimeline,
        { remarks: remark, timestamp: new Date() }
      ]
    }));

    setLoading(false);
    setSnackbarMessage('Order Cancelled!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  } catch (error) {
    setLoading(false);
    setSnackbarMessage('Failed to cancel order!');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

export const duplicateOrder = async (orderData, sellerID, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen) => {
  const duplicatedOrder = {
    ...orderData,
    _id: undefined,
    orderID: '',
    status: 'Order Placed',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    waybill: '',
    shippingCost: '',
    orderTimeline: [
      { remarks: 'Order Created', timestamp: new Date() }
    ] 
  };

  try {
    setLoading(true);
    const response = await sellerRequest.post(`/order`, duplicatedOrder, {
      withCredentials: true
    });
    // console.log('Duplicated order created', response.data);
    setLoading(false);
    setSnackbarMessage(`Order cloned successfully!   Order ID:${response.data.orderID}`);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  } catch (error) {
    console.error('Error cloning order:', error);
    setLoading(false);
    setSnackbarMessage('Failed to clone order!');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

export const updateOrderStatus = async (orderData, sellerID, newStatus, selectedWarehouseID, selectedShippingService, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, setOrderData, setWarehouseWarning) => {
  // console.log("Shipping service", selectedShippingService);
  // console.log(`Updating order status to ${newStatus}`);

  if (!selectedShippingService) {
    setWarehouseWarning('Select a shipping service');
    return;
  }

  if (!selectedWarehouseID && newStatus === "Order Confirmed") {
    setWarehouseWarning('Select a warehouse');
    return;
  }

  setLoading(true);

  try {
    const updatedOrderData = {
      ...orderData,
      status: newStatus,
      warehouseID: selectedWarehouseID,
      shippingService: selectedShippingService
    };
    const response = await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
    // console.log(response);
    
    // if (newStatus === "Order Confirmed") {
    //   await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: "Order Confirmed" });
    // }
    // if (newStatus === "Order Processing") {
    //   await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: "Moved to Processing" });
    // }
    // if (newStatus === "Manifested") {
    //   await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: "Moved Manifested" });
    // }
    // if (newStatus === "Shipped") {
    //   await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: "Order Shipped" });
    // }
    // if (newStatus === "Delivered") {
    //   await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: "Order Delivered" });
    // }
    const statusRemarksMap = {
      "Order Confirmed": "Order has been confirmed.",
      "Order Processing": "Order is now being processed.",
      "Manifested": "Order has been manifested.",
      "Shipped": "Order has been shipped.",
      "Delivered": "Order has been delivered."
    };
    const remark = statusRemarksMap[newStatus];
    if (remark) {
      await sellerRequest.put(`/order/updateOrderTimeline/${sellerID}/${orderData.orderID}`, { remarks: remark });
      setOrderData(prevOrderData => ({
        ...prevOrderData,
        orderTimeline: [
          ...prevOrderData.orderTimeline,
          { remarks: remark, timestamp: new Date() }
        ]
      }));
    }

    setOrderData(prevOrderData => ({
      ...prevOrderData,
      status: newStatus,
      warehouseID: selectedWarehouseID,
      shippingService: selectedShippingService
      

    }));

    setLoading(false);
    setSnackbarMessage(`Order status updated to ${newStatus} successfully!`);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Request error:', error);
    setLoading(false);
    setSnackbarMessage(`Failed to update order status to ${newStatus}!`);
    setSnackbarSeverity('error');
  } finally {
    setSnackbarOpen(true);
  }
};

export const updateOrderTotalWeight = async (orderData, sellerID, packageDetails, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, setOrderData) => {
  // console.log("Updating order total weight to ", packageDetails);

  setLoading(true);

  try {
    const updatedOrderData = {
      ...orderData,
      shipment: {
        length: packageDetails.length,
        breadth: packageDetails.breadth,
        height: packageDetails.height,
        totalWeight: packageDetails.weight,
      },
    };
    const response = await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
    // console.log(response);
    setOrderData(prevOrderData => ({
      ...prevOrderData,
      shipment: {
        ...orderData.shipment,
        totalWeight: packageDetails.weight,
      },
    }));

    setLoading(false);
    setSnackbarMessage(`Order total weight updated to ${packageDetails.weight} successfully!`);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Request error:', error);
    setLoading(false);
    setSnackbarMessage(`Failed to update order total weight to ${packageDetails.weight}!`);
    setSnackbarSeverity('error');
  } finally {
    setSnackbarOpen(true);
  }
};

import { useState } from 'react';

const useConfirmation = () => {
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState({});

  const showConfirmation = (heading, content, cancelLabel = "Cancel", confirmLabel = "Confirm") => {
    return new Promise((resolve, reject) => {
      setConfirmationAction({
        heading,
        content,
        cancelLabel,
        confirmLabel,
        onConfirm: () => {
          setConfirmationVisible(false);
          resolve(true); // User confirmed
        },
        onCancel: () => {
          setConfirmationVisible(false);
          reject(false); // User canceled
        },
      });
      setConfirmationVisible(true); // Show confirmation box
    });
  };

  return { isConfirmationVisible, showConfirmation, confirmationAction, setConfirmationVisible };
};

export default useConfirmation;